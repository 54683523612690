.status-lotto{

    .header-button-group{

    }

    .table-contain{

        .card-table{
            width: 90%;
            margin: auto;
            margin-bottom: 3rem;
    
            .head1{
                td{
                    text-align: center;
                    font-size: 0.9rem;
                    font-weight: bold;
                    background-color: #FFD700;
                    padding: 0 10px;
                }
            }
    
            .head2{
                
                td{
                    width: 10rem;
                    padding: 0.2rem 0;
    
                    .type-button{
                        width: calc(6rem + 1vmin);
                        font-size: calc(6px + 1vmin);
                        font-weight: bold;
                        background-color: #fff;
                        color: #17C1E8;
                        border: 2px solid #17C1E8;
                        border-radius: 10px;
                        text-align: center;
                        cursor: pointer;
                        margin: 0 auto;
                        height: 2rem;
                        line-height: 1.8rem;
                    }
    
                    .type-button:hover{
                        background-color: #17C1E8;
                        color: #fff;
                    }
                }
            }
    
            .body1{
                td{
                    padding: 0.2rem 0;
                }
            }
    
            .body2{
                td{
                    text-align: center;
                    padding: 0.2rem 0;
                    font-size: 0.9rem;
                    background-color: #fff;
                    padding: 0 10px;
                }

                .text-with-flag{
                    display: flex;
                    align-items: center;
                }

                img{
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: 50%;
                    image-rendering: -webkit-optimize-contrast;
                    margin-right: 0.5rem;
                }

                .type-number{
                    display: flex;
                    justify-content: right;

                    .text-type{
                        width: 5rem;
                        text-align: right;
                        color: red;
                    }

                    .text-add{
                        width: 1rem;
                    }

                    .text-number{
                        width: 2rem;
                        text-align: right;
                        color: blue;
                    }
                }
    
                .text-left{
                    text-align: left;
                }
    
                .text-right{
                    text-align: right !important;
                }
    
                .text-center{
                    text-align: center;
                }

                .text-blue{
                    color: blue;
                }
    
                .text-red{
                    color: red;
                    text-align: center;
                }
    
                .text-green{
                    color: green;
                    text-align: center;
                }

                .green-row{
                    td{
                        background-color: #CCFFCC;
                    }
                }
    
                .close-limit{
                    background: rgba(255,98,88,.2);
                }
    
                .nav-link{
                    color: blue;
                    text-align: center;
                    text-decoration: underline;
                    cursor: pointer;
                }
    
                .nav-link:hover{
                    color: #17C1E8;
                }

                .detail-bill-contain{
                    padding: 1rem;
                    background-color: #CCCCFF;

                    .card-table .head1 td{
                        background-color: #FFD700;
                    }
                }
            }
    
            .table-flex{
                display: flex;
                padding: 0 10px;
            }
    
            .td-text{
                font-size: calc(4px + 1vmin);
                display: flex;
                align-items: center;
                margin-left: 3px;
            }
        }

    }

}