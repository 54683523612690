.dashboard{
    display: block;
    // text-align: center;

    .news-card{
        width: 100%;
        display: flex;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 1rem;

        @media(max-width:766px){
            padding: 0.5rem;
        }

        .image-contain{

            @media(max-width:766px){
                display: flex;
                justify-content: center;
                align-items: center;
            }

            img{
                width: 8rem;
                height: auto;

                @media(max-width:766px){
                    width: 4rem;
                }
            }
        }

        .text-contain{
            width: 100%;
            margin: auto;
            margin-left: 2rem;

            @media(max-width:766px){
                margin-left: 1rem;
            }

            .text-title{
                font-size: 1.1rem;
                font-size: calc(8px + 1vmin);
                font-weight: bold;
            }
            .text-desc{
                font-size: 1rem;
                font-size: calc(6px + 1vmin);
            }
        }
    }
}