.list-select {
  .isLoading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-box {
    display: flex;
    padding-bottom: 1rem;

    .search-input {
      width: 30%;
      display: flex;
      justify-content: left;

      p {
        display: flex;
        align-items: center;
        font-size: 1rem;
        padding-right: 0.5rem;
      }
    }

    .search-select {
      width: 100%;
      display: flex;
      justify-content: right;

      p {
        display: flex;
        align-items: center;
        font-size: 1rem;
        padding-right: 0.5rem;
      }
    }
  }

  // table{
  //     width: 100%;
  //     thead{
  //         padding: 0;
  //         display: contents;
  //         tr{
  //             th{
  //                 background-color: #FFD700;
  //                 color: #000;
  //                 font-size: 0.8rem;
  //                 text-align: center;
  //                 padding: 0;
  //             }
  //         }
  //     }
  //     tbody{
  //         tr{
  //             td{
  //                 background-color: #fff;
  //                 color: #000;
  //                 font-size: 0.8rem;
  //                 text-align: center;
  //                 cursor: pointer;
  //                 padding: 0.5rem 0;

  //                 .click-button{
  //                     color: blue;
  //                     cursor: pointer;
  //                 }
  //             }
  //         }
  //         tr:hover{
  //             background-color: #CCCCFF;
  //         }
  //         .bill-detail{
  //             font-size: 0.8rem;
  //         }
  //     }

  //     .detail-bill-contain{
  //         padding: 1rem;
  //         background-color: #CCCCFF;
  //     }

  //     .detail-bill-table-header{
  //         td{
  //             background-color: #FFD700;
  //             color: #000;
  //             font-size: 0.8rem;
  //             text-align: center;
  //         }
  //     }
  // }
}
