.list-lotto-contain{
    background-color: #fff;
    border-radius: 15px;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;

    @media(max-width:766px){
        padding: 0.5rem 0rem;
    }

    .lotto-header{
        color: #000;
        // font-size: 1.5rem;
        font-size: calc(14px + 1vmin);
        padding: 0 1.5rem;
    }
    .lotto-card{
        display: flex;
        flex-wrap: wrap;
    }
}

.list-lotto-card{
    background-color: #CEE9F6;
    cursor: pointer;
    border-radius: 5px;
    width: 24rem;

    margin: 1rem;
    text-align: center;
    font-size: 1rem;
    padding: 0.5rem 0;

    @media(max-width:766px){
        width: 18rem;
        font-size: calc(8px + 1vmin);
    }

    .image-box{
        display: flex;
        align-items: center;
        padding: 0.5rem;

        .flag-img{
            display: flex;
            align-items: center;

            img{
                height: 3rem;
                width: auto;
                margin: 0 0.5rem;
                border-radius: 5px;
            }
        }

        .text-right{
            width: 100%;
            text-align: right;
        }
    }

    .text-box{
        text-align: left;

        .text-box-header{
            font-size: 1.5rem;
            font-weight: bold;
        }

        .display-flex{
            display: grid;
            grid-template-columns: 33% auto;
            border-top: 2px solid #fff;
            border-bottom: 2px solid #fff;

            .display-flex-left{
                border-right: 2px solid #fff;
                padding: 0.5rem;
                text-align: center;
            }

            .display-flex-right{
                padding: 0.5rem;
            }
        }

        .countdown-contain{
            padding: 0.5rem;
            text-align: center;
        }
    }

    .text-red{
        color: red;
        font-size: 1rem;
        font-weight: bold;

        @media(max-width:766px){
            font-size: calc(10px + 1vmin);
        }
    }
}

.disable-card{
    background-color: #E0C5C5;
    cursor: no-drop;
}

.text-red-contain{
    text-align: center;

    p{
        color: red;
        font-size: 1.2rem;
        font-weight: bold;
    
        @media(max-width:766px){
            font-size: calc(10px + 1vmin);
        }
    }

}