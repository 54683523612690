.navbar {
  margin-bottom: 2rem;

  .navbar-contain {
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;

    .navbar-item {
      background-color: #9bc6db;
      color: #0890d0;
      border: 1px solid #0890d0;
      border-radius: 10px 10px 0 0;
      padding: 0.5rem 1rem;
      cursor: pointer;
      font-size: calc(8px + 1vmin);
      font-weight: bold;
      margin-bottom: 1rem;
      white-space: nowrap;
    }

    .navbar-item:hover {
      background-color: #0890d0;
      color: #fff;
      font-size: calc(8px + 1vmin);
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .navbar-item-active {
      background-color: #0890d0;
      color: #fff;
      border: 0px;
      border-radius: 10px 10px 0 0;
      padding: 0.5rem 1rem;
      cursor: pointer;
      font-size: calc(8px + 1vmin);
      font-weight: bold;
      margin-bottom: 1rem;
      white-space: nowrap;
    }

    .mobile-menu {
      &_hamburger {
        cursor: pointer;

        @media (min-width: 1200px) {
          display: none;
        }

        &:hover &__layer {
          background-color: #1accff;
          color: #fff;
        }

        &__layer {
          display: block;
          width: 20px;
          height: 3px;
          margin-bottom: 3px;
          position: relative;
          background-color: #0890d0;
          border-radius: 10px;
          z-index: 1;
        }

        &__layer:nth-child(2) {
          // width: 20px;
          margin: 0 auto;
          margin-bottom: 3px;
        }
      }
    }
  }
}

.hamburger {
  width: 250px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  transition: width 0.25s;
  transform: translateX(100%);
  z-index: 9999;

  &.is-active {
    transform: translateX(0%);
  }

  &.not-active {
    display: none;
  }

  @media (min-width: 1200px) {
    display: none;
  }

  .mobile-menu {
    &_hamburger {
      width: 1.2rem;
      margin-left: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
      cursor: pointer;

      @media (min-width: 1200px) {
        display: none;
      }

      &:hover &__layer {
        background-color: #1accff;
        color: #fff;
      }

      &__layer {
        display: block;
        width: 20px;
        height: 3px;
        margin-bottom: 3px;
        position: relative;
        background-color: #0890d0;
        border-radius: 10px;
        z-index: 1;
      }

      &__layer:nth-child(2) {
        // width: 20px;
        margin: 0 auto;
        margin-bottom: 3px;
      }
    }
  }
}
