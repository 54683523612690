.play-game {
  // margin: 0 1rem;
  .card-play {
    width: 68%;
    margin-right: 1rem;
    @media (max-width: 1200px) {
      width: 100%;
    }
  }
  .card-showbill {
    width: 30%;
    @media (max-width: 1200px) {
      width: 100%;
    }
  }
  .isLoading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-header {
    display: flex;
    background-color: #fff;
    padding: 0.5rem 1rem;
    // margin-bottom: 1rem;

    .card-header-text {
      font-size: calc(10px + 1vmin);
      font-weight: bold;
    }
    .card-header-text2 {
      font-size: calc(6px + 1vmin);
      margin-left: auto;
      color: red;
    }
  }

  .card-left {
    .add-bill {
      display: flex;
      justify-content: center;
      font-size: 1rem;
      line-height: 1.2rem;
      margin: 1rem;
      border-radius: 10px;
      padding: 1rem;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      // background-color: #f2f2f2;
      .lotto-detail {
        width: 16%;
        text-align: center;

        @media (max-width: 766px) {
          width: 20%;
        }

        p {
          font-weight: bold;

          @media (max-width: 766px) {
            font-size: calc(8px + 1vmin);
          }
        }
      }
      .lotto-detail-text {
        width: 100%;
        text-align: center;

        @media (max-width: 766px) {
          width: 20%;
        }

        p {
          font-weight: bold;

          @media (max-width: 766px) {
            font-size: calc(8px + 1vmin);
          }
        }
      }

      .lotto-number {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 0 2rem;

        @media (max-width: 766px) {
          margin: 0 1rem;
        }

        span {
          font-weight: bold;
          margin: 0 0.2rem;

          @media (max-width: 766px) {
            font-size: calc(8px + 1vmin);
          }
        }
      }
      .lotto-number-text {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 0 0.5rem;

        @media (max-width: 766px) {
          margin: 0 1rem;
        }

        span {
          font-weight: bold;
          margin: 0 0.2rem;

          @media (max-width: 766px) {
            font-size: calc(8px + 1vmin);
          }
        }
      }
      .delete-button-group {
        width: 10%;
        text-align: center;
        display: flex;
        align-items: center;

        @media (max-width: 766px) {
          width: 15%;
        }

        .button {
          width: 3rem;
          height: 2rem;
          background-color: red;
          color: #fff;
          line-height: 2rem;
          border-radius: 5px;
          cursor: pointer;
        }
      }
      .delete-button-group-text {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;

        @media (max-width: 766px) {
          width: 15%;
        }

        .button {
          width: 3rem;
          height: 2rem;
          background-color: red;
          color: #fff;
          line-height: 2rem;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }

    .card-header {
      display: flex;
      margin-bottom: 1rem;

      .card-header-text {
        font-size: calc(10px + 1vmin);
      }
      .card-header-text2 {
        font-size: calc(6px + 1vmin);
        margin-left: auto;
        color: red;
      }
    }

    .button-group-center {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
      gap: 8px;

      .group {
        display: flex;
        width: 100%;
        justify-content: center;
        border-radius: 3px;

        .type-button {
          width: calc(6rem + 2vmin);
          height: 2.5rem;
          font-size: calc(10px + 1vmin);
          font-weight: bold;
          background-color: #9bc6db;
          color: #0890d0;
          border: 1px solid #64b6db;
          border-radius: 10px;
          text-align: center;
          cursor: pointer;
          margin: 0 0.5rem;
          line-height: 2.5rem;

          @media (max-width: 766px) {
            width: calc(4.5rem + 1vmin);
            font-size: calc(8px + 1vmin);
            margin: 0 0.25rem;
          }
        }
        .type-button:hover {
          background-color: #0890d0;
          color: #fff;
        }
      }
    }

    .button-select {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 1rem;

      .group {
        display: flex;
        width: fit-content;
        border-radius: 3px;
        overflow: hidden;
        gap: 1px;
        background: #64b6db;
        border: 1px solid #64b6db;

        .type-button {
          width: calc(2rem + 1vmin);
          height: 3rem;
          font-size: calc(10px + 1vmin);
          font-weight: bold;
          background-color: #9bc6db;
          color: #0890d0;
          text-align: center;
          cursor: pointer;
          line-height: 3rem;
          transition: background-color 0.15s ease;

          @media (max-width: 766px) {
            width: calc(4.5rem + 1vmin);
            font-size: calc(8px + 1vmin);
            margin: 0 0.25rem;
          }
        }
        .type-button:hover {
          background-color: #9bc6dbe8;
          color: #0890d0;
        }
        .active {
          background-color: #0890d0;
          color: #fff;
        }

        .active:hover {
          background-color: #4aacdd !important;
          color: #fff;
        }
      }
    }

    .button-group {
      display: flex;
      margin-bottom: 1rem;

      .type-button {
        width: calc(6rem + 1vmin);
        height: 2.5rem;
        font-size: calc(10px + 1vmin);
        font-weight: bold;
        background-color: #9bc6db;
        color: #0890d0;
        border: 1px solid #0890d0;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        margin: 0 0.5rem;
        line-height: 2.5rem;

        @media (max-width: 766px) {
          width: calc(4.5rem + 1vmin);
          font-size: calc(8px + 1vmin);
          margin: 0 0.25rem;
        }
      }
      .type-button:hover {
        background-color: #0890d0;
        color: #fff;
      }
      .active {
        background-color: #0890d0;
        color: #fff;
      }
    }

    background-color: #fff;
    padding: 0.5rem;
    .card-table {
      width: 100%;

      .head1 {
        td {
          width: 25%;
          font-size: calc(8px + 1vmin);
          text-align: center;
          font-weight: bold;
          background-color: #ffd700;
        }
      }

      .head2 {
        td {
          width: 10rem;
          padding: 0.2rem 0;

          .type-button {
            width: calc(6rem + 1vmin);
            font-size: calc(6px + 1vmin);
            font-weight: bold;
            background-color: #0890d0;
            color: #fff;
            border: 1px solid #0890d0;
            border-radius: 10px;
            text-align: center;
            cursor: pointer;
            margin: 0 auto;
            height: 1.8rem;
            line-height: 1.8rem;

            @media (max-width: 766px) {
              width: calc(4.5rem + 1vmin);
              font-size: calc(8px + 1vmin);
            }
          }

          // .type-button:hover{
          //     background-color: #17C1E8;
          //     color: #fff;
          // }
        }
      }

      .body1 {
        td {
          padding: 0.2rem 0;
        }
      }

      .body2 {
        td {
          padding: 0.2rem 0;
          text-align: center;
          font-size: 0.8rem;
        }

        .text-red {
          color: red;
          font-weight: bold;
        }

        .close-limit {
          background: rgba(255, 98, 88, 0.2);
        }
      }

      .table-flex {
        display: flex;
        justify-content: center;
        padding: 0 10px;

        @media (max-width: 766px) {
          padding: 0;
        }

        .css-16ufdgp-MuiInputBase-root {
          padding: 0rem;
          border: none;
          box-shadow: unset !important;
        }

        input {
          font-size: 16px;
          font-weight: bold;
          border: 1px solid #0890d0;
          border-radius: 5px;
          padding: 0.2rem;
          height: 1.8rem;
          padding: 0 0.5rem !important;

          @media (max-width: 766px) {
            font-size: calc(8px + 1vmin);
          }
        }
      }

      .table-flex-run {
        display: flex;
        padding: 0 10px;

        .css-16ufdgp-MuiInputBase-root {
          padding: 0rem;
          border: none;
          box-shadow: unset !important;
        }

        input {
          font-size: 16px;
          font-weight: bold;
          border: 1px solid #0890d0;
          border-radius: 5px 0 0 5px;
          padding: 0.2rem;
          height: 1.8rem;
          padding: 0 0.5rem !important;

          @media (max-width: 766px) {
            font-size: calc(8px + 1vmin);
          }
        }
      }

      .text-center {
        input {
          text-align: center;
        }
      }

      .text-right {
        input {
          text-align: right;
          border-radius: 5px 0 0 5px;
        }
      }

      .td-text {
        font-size: calc(4px + 1vmin);
        color: #fff;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 0 5px;
        background-color: #0890d0;
        border-radius: 0 5px 5px 0;
      }
    }

    .card-bottom {
      display: flex;
      justify-content: center;
      font-size: calc(6px + 1vmin);
      padding: 3rem 0 1rem 0;

      .css-16ufdgp-MuiInputBase-root {
      }

      .text-memmory {
        @media (max-width: 766px) {
          width: 50%;
        }
      }

      p {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        font-weight: bold;

        @media (max-width: 766px) {
          margin: 0 0.25rem;
        }
      }

      .total-price {
        // margin-left: 2rem;
        background-color: #0890d0;
        color: #fff;
        padding: 0 2rem;
        border-radius: 5px;

        @media (max-width: 766px) {
          margin-left: 0;
          margin-right: 0;
          padding: 0 1.5rem;
        }
      }
    }

    .bottom-button-group {
      display: flex;
      justify-content: center;

      .button-clear {
        width: calc(6rem + 1vmin);
        font-size: calc(6px + 1vmin);
        font-weight: bold;
        background-color: #fff;
        color: #17c1e8;
        border: 2px solid #17c1e8;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        margin: 0 0.5rem;
        height: 2rem;
        line-height: 1.8rem;
      }
      .button-save {
        width: calc(6rem + 1vmin);
        font-size: calc(6px + 1vmin);
        font-weight: bold;
        background-color: #17c1e8;
        color: #fff;
        border: 2px solid #17c1e8;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        margin: 0 0.5rem;
        height: 2rem;
        line-height: 1.8rem;
      }
    }

    .flash-card {
      .header-contain {
        display: flex;
        margin: 1rem 0;

        .left-contain {
          margin-left: 3rem;
          font-size: 14px;
          font-weight: bold;

          @media (max-width: 766px) {
            font-size: calc(8px + 1vmin);
            margin-left: 2rem;
            width: 50%;
          }
        }

        .right-contain {
          margin-left: auto;
          font-size: 14px;
          font-weight: bold;

          @media (max-width: 766px) {
            font-size: calc(8px + 1vmin);
          }
        }

        ul {
          margin: 0 2rem;
          font-size: calc(4px + 1vmin);

          @media (max-width: 766px) {
            margin: 0;
          }
        }
      }

      .two-digits {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 766px) {
          display: block;
        }

        .block-flex {
          display: flex;

          @media (max-width: 766px) {
            justify-content: center;
            margin-bottom: 1rem;
          }
        }

        .css-16ufdgp-MuiInputBase-root {
          border: 1px solid #0890d0;
          box-shadow: unset !important;
        }

        .input-box {
          width: 5rem;

          input {
            text-align: center;
            font-size: 16px;
            font-weight: bold;

            @media (max-width: 766px) {
              font-size: calc(8px + 1vmin);
            }
          }
        }

        p {
          font-size: calc(6px + 1vmin);
          font-weight: bold;
          display: flex;
          align-items: center;
          margin: 0 0.5rem;

          @media (max-width: 766px) {
            font-size: calc(8px + 1vmin);
          }
        }

        .button-contain {
          display: flex;
          align-items: center;

          .type-button {
            width: calc(6rem + 1vmin);
            font-size: calc(8px + 1vmin);
            font-weight: bold;
            background-color: #0890d0;
            color: #fff;
            border: 1px solid #0890d0;
            border-radius: 10px;
            text-align: center;
            cursor: pointer;
            margin: 0 0.5rem;
            height: 2.5rem;
            line-height: 2.4rem;

            @media (max-width: 766px) {
              width: calc(4rem + 1vmin);
              font-size: calc(8px + 1vmin);
            }
          }
        }
      }

      .two-digits-bill {
        display: flex;
        justify-content: center;
      }

      .three-digits-button-group {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        .type-button {
          width: calc(6rem + 1vmin);
          font-size: calc(6px + 1vmin);
          font-weight: bold;
          background-color: #0890d0;
          color: #fff;
          border: 1px solid #0890d0;
          border-radius: 10px;
          text-align: center;
          cursor: pointer;
          margin: 0 0.5rem;
          height: 2rem;
          line-height: 1.8rem;

          @media (max-width: 766px) {
            width: calc(4.5rem + 1vmin);
            font-size: calc(8px + 1vmin);
          }
        }
      }
      .lotto-number-delete {
        background-color: #344767;
        color: #fff;
        // width: auto;
        width: 5rem;
        text-align: center;
        margin: 0.3rem;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        @media (max-width: 766px) {
        }
      }
      
      .label-total-number {
        font-size: 1rem;
        margin-left: 6px;

        @media (max-width: 766px) {
          font-size: calc(8px + 1vmin);
        }
      }

      .bg-show-number{
        background-color: #daf3ff;
        color: #fff;
        padding: 0 0.5rem;
        border-radius: 5px;
        margin-left: 5px;
        min-height: 6rem;
      }

      .lotto-number-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        

        @media (max-width: 766px) {
          justify-content: center;
        }
      }

      .lotto-number-item-value {
        background-color: red;
        color: #fff;
        // min-width: 3rem;
        padding: 0 8px;
        text-align: center;
        margin: 0.3rem;
        border-radius: 5px;
        cursor: pointer;

        @media (max-width: 766px) {
        }
      }
      .lotto-number-item-blue-value {
        background-color: #32cd32;
        color: #fff;
        // min-width: 3rem;
        padding: 0 8px;
        text-align: center;
        margin: 0.3rem;
        border-radius: 5px;
        cursor: pointer;

        @media (max-width: 766px) {
        }
      }

      .radio-option {
        display: flex;
        align-items: center;
        margin-left: 1rem;

        span {
          font-size: 1rem;
          font-weight: bold;
          margin: 0 16px 0 5px;

          @media (max-width: 766px) {
            font-size: calc(8px + 1vmin);
          }
        }

        input {
          width: 20px;
          height: 20px;

          @media (max-width: 766px) {
            font-size: calc(8px + 1vmin);
          }
        }
      }
    }
  }

  .card-right {
    background-color: #fff;

    @media (max-width: 766px) {
      padding: 0.5rem;
    }

    .card-table {
      width: 100%;

      .head1 {
        td {
          font-size: 0.8rem;
          text-align: center;
          font-weight: bold;
          background-color: #ffd700;

          @media (max-width: 766px) {
            font-size: 0.6rem;
          }
        }
      }

      .head2 {
        td {
          width: 10rem;
          padding: 0.2rem 0;

          .type-button {
            width: calc(6rem + 1vmin);
            font-size: calc(6px + 1vmin);
            font-weight: bold;
            background-color: #0890d0;
            color: #fff;
            border: 1px solid #0890d0;
            border-radius: 10px;
            text-align: center;
            cursor: pointer;
            margin: 0 auto;
            height: 2rem;
            line-height: 1.8rem;

            @media (max-width: 766px) {
              width: calc(4.5rem + 1vmin);
              font-size: calc(8px + 1vmin);
            }
          }
        }
      }

      .body1 {
        td {
          padding: 0.2rem 0;
        }
      }

      .body2 {
        td {
          padding: 0.2rem 0;
          text-align: center;
          font-size: 0.6rem;
          background-color: #fff;
        }
      }

      .table-flex {
        display: flex;
        padding: 0 10px;
      }

      .td-text {
        font-size: calc(4px + 1vmin);
        display: flex;
        align-items: center;
        margin-left: 3px;
      }
    }
    .show-all-bill {
      text-align: center;
      background-color: #fff;
      padding: 0.5rem 0;

      .button {
        cursor: pointer;
        color: blue;
        font-size: 0.8rem;
        width: 7rem;
        margin: 0 auto;
      }
    }
  }
}
