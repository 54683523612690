.rule {
  p {
    font-size: 1rem;

    @media (max-width: 766px) {
      font-size: 0.9rem;
    }
  }

  ul {
    margin-left: 1rem;

    li {
      font-size: 1rem;

      @media (max-width: 766px) {
        font-size: 0.9rem;
      }
    }
  }
}
