.play-game-list-select{

    .search-box{
        display: flex;
        padding-bottom: 1rem;

        .search-input{
            width: 30%;
            display: flex;
            justify-content: left;

            p{
                display: flex;
                align-items: center;
                font-size: 1rem;
                padding-right: 0.5rem;
            }
        }

        .search-select{
            width: 100%;
            display: flex;
            justify-content: right;

            p{
                display: flex;
                align-items: center;
                font-size: 1rem;
                padding-right: 0.5rem;
            }
        }
    }

    table{
        width: 100%;
        thead{
            padding: 1rem 0.1rem;
            display: contents;
            tr{
                th{
                    background-color: #FFD700;
                    color: #000;
                    font-size: 0.8rem;
                    font-weight: bold;
                    text-align: center;
                    padding: 0;
                }
            }
        }
        tbody{
            tr{
                td{
                    background-color: #fff;
                    color: #000;
                    font-size: 0.8rem;
                    text-align: center;
                    cursor: pointer;
                    padding: 0.3rem 0;

                    @media(max-width:766px){
                        font-size: 0.6rem;
                    }

                    .click-button{
                        color: blue;
                        cursor: pointer;
                    }
                }
            }
            tr:hover{
                background-color: #CCCCFF;
            }
            .bill-detail{
                font-size: 0.8rem;
            }
        }

        .detail-bill-contain{
            padding: 1rem;
            background-color: #CCCCFF;
        }

        .detail-bill-table-header{
            td{
                background-color: #FFD700;
                color: #000;
                font-size: 0.8rem;
                text-align: center;
            }
        }
    }
}