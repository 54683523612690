.financial{

    .isLoading{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .search-box{
        display: flex;

        .search-input{
            width: 30%;
            display: flex;
            justify-content: left;

            p{
                display: flex;
                align-items: center;
                font-size: 1rem;
                padding-right: 0.5rem;
            }
        }

        .search-select{
            width: 100%;
            display: flex;
            justify-content: right;

            p{
                display: flex;
                align-items: center;
                font-size: 1rem;
                padding-right: 0.5rem;
            }
        }
    }

    .card-table{
        // width: 90%;
        margin: auto;
        margin-bottom: 3rem;

        @media(max-width:766px){
            
        }

        .head1{
            td{
                text-align: center;
                font-size: 0.9rem;
                font-weight: bold;
                background-color: #FFD700;
                padding: 0 10px;

                @media(max-width:766px){
                    font-size: 0.8rem;
                }
            }
        }

        .head2{
            
            td{
                width: 10rem;
                padding: 0.2rem 0;

                .type-button{
                    width: calc(6rem + 1vmin);
                    font-size: calc(6px + 1vmin);
                    font-weight: bold;
                    background-color: #fff;
                    color: #17C1E8;
                    border: 2px solid #17C1E8;
                    border-radius: 10px;
                    text-align: center;
                    cursor: pointer;
                    margin: 0 auto;
                    height: 2rem;
                    line-height: 1.8rem;
                }

                .type-button:hover{
                    background-color: #17C1E8;
                    color: #fff;
                }
            }
        }

        .body1{
            td{
                padding: 0.2rem 0;
            }
        }

        .body2{
            td{
                padding: 0.2rem 0;
                font-size: 0.9rem;
                background-color: #fff;
                padding: 0 10px;

                @media(max-width:766px){
                    font-size: 0.8rem;
                }
            }

            .text-left{
                text-align: left;
            }

            .text-right{
                text-align: right;
            }

            .text-center{
                text-align: center;
            }

            .text-red{
                color: red;
                text-align: center;
            }

            .text-green{
                color: green;
                text-align: center;
            }

            .close-limit{
                background: rgba(255,98,88,.2);
            }

            .nav-link{
                color: blue;
                text-align: center;
                text-decoration: underline;
                cursor: pointer;
            }

            .nav-link:hover{
                color: #17C1E8;
            }
        }

        .table-flex{
            display: flex;
            padding: 0 10px;
        }

        .td-text{
            font-size: calc(4px + 1vmin);
            display: flex;
            align-items: center;
            margin-left: 3px;
        }
    }

    .card-table2{
        width: 50%;
        margin: auto;

        @media(max-width:766px){
            width: 100%;
        }

        .head1{
            td{
                width: 25%;
                font-size: 0.9rem;
                text-align: left;
                font-weight: bold;
                background-color: #FFD700;
                padding: 0 10px;
            }
        }

        .head2{
            
            td{
                width: 10rem;
                padding: 0.2rem 0;

                .type-button{
                    width: calc(6rem + 1vmin);
                    font-size: calc(6px + 1vmin);
                    font-weight: bold;
                    background-color: #fff;
                    color: #17C1E8;
                    border: 2px solid #17C1E8;
                    border-radius: 10px;
                    text-align: center;
                    cursor: pointer;
                    margin: 0 auto;
                    height: 2rem;
                    line-height: 1.8rem;
                }

                .type-button:hover{
                    background-color: #17C1E8;
                    color: #fff;
                }
            }
        }

        .body1{
            td{
                padding: 0.2rem 0;
            }
        }

        .body2{
            td{
                padding: 0.2rem 0;
                font-size: 0.9rem;
                background-color: #fff;
                padding: 0 10px;
            }

            .text-left{
                text-align: left;
            }

            .text-right{
                text-align: right;
            }

            .text-red{
                color: red;
                font-weight: bold;
            }

            .close-limit{
                background: rgba(255,98,88,.2);
            }
        }

        .table-flex{
            display: flex;
            padding: 0 10px;
        }

        .td-text{
            font-size: calc(4px + 1vmin);
            display: flex;
            align-items: center;
            margin-left: 3px;
        }
    }
}